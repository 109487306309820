import React from "react";
import Markdown from "react-markdown";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import InfoPaper from "../../components/cards/info-card";
import ReasearchCard from "../../components/cards/research-card";
import baseStyles from "../../css/const";
import Paper from "@material-ui/core/Paper";
import DefaultLayout from "../../components/layouts/default-layout";
import useDocTitle from "../../hooks/use-doc-title";
import useTracker from "../../hooks/use-tracker";
import { Button, Card, Collapse } from "@material-ui/core";
import CodeBlock from "../../components/markdown/code-block";
import { renderVideo } from "../../components/cards/video";


export function ExampleCode({ source, ...rest }) {
  const [markdown, setMarkdown] = React.useState("");

  React.useEffect(() => {
    // dynamically import the Markdown file and fetch it
    import(`../../assets/markdowns/code-examples/${source}`).then((res) => {
      fetch(res.default)
        .then((res) => res.text())
        .then((res) => setMarkdown(res));
    });
  }, [source]);

  return <Markdown className={rest.className} source={markdown} renderers={{ code: CodeBlock }} />;
}

function ExpandCollapseButton(props) {
  const classes = baseStyles();

  return (
    <Button className={classes.button} variant="contained" onClick={props.onClick}>
      View {props.expand ? "More" : "Less"}
    </Button>
  );
}

function Description({ source, picture, ...rest }) {
  const [markdown, setMarkdown] = React.useState("");

  React.useEffect(() => {
    // dynamically import the Markdown file and fetch it 
    import(`../../assets/markdowns/tool-descriptions/${source}`).then((res) => {
      fetch(res.default)
        .then((res) => res.text())
        .then((res) => setMarkdown(res));
    });
  }, [source]);

  return <Markdown className={rest.className} source={markdown} renderers={{ code: CodeBlock }} />;
}

function Picture({ src }) {
  if (src) {
    return (
      <img
        src={require("../../assets/" + src).default}
        alt="general_picture"
        style={{ maxWidth: "90%", maxHeight: "550px" }}
      />
    );
  }

  return null;
}

function Section({ type, title, content }) {
  const classes = baseStyles();
  return (
    <Grid container alignItems="stretch" spacing={2}>
      <Grid item xs={12}>
        <Typography className={classes.subtitle}>{title}</Typography>
      </Grid>

      {content.map((item) => {
        if (type === "links") {
          const props = {
            title: item.title,
            link: 'link' in item ? item.link : require("../../assets/" + item.static_link).default,
            description: item.description,
          };

          return (
            <Grid item xs={12} sm={4}>
              <InfoPaper {...props} />
            </Grid>
          );
        } else if (type === "tutorials") {
          const props = {
            title: item.title,
            link: item.link,
            description: item.description,
            bg_image: item.bg_image,
          };

          return (
            <Grid item sm={6} md={4}>
              <InfoPaper {...props} />
            </Grid>
          );
        } else {
          const props = {
            title: item.title,
            authors: item.authors,
            BibTex: item.BibTex,
            more: item.more,
            fullText: item.fullText,
          };

          return (
            <Grid item sm={12} md={0} alignContent={"flex-start"} alignItems={"flex-start"}>
              <ReasearchCard {...props} />
            </Grid>
          );
        }
      })}
    </Grid>
  );
}

export default function Tool(props) {
  // set document title
  const [title, setTitle] = useDocTitle(props.input.general.title);

  // setup tracker after setting the document title
  useTracker();

  const [showMoreVideos, setShowMoreVideos] = React.useState(false);

  const { input } = props;

  const content = input;

  const classes = baseStyles();
  const width = content.general.picture ? 8 : 12;
  return (
    <body className={classes.body}>
      <DefaultLayout>
        <main>
          <Grid container justify="left" spacing={2}>
            <Paper className={classes.paper} variant={"outlined"}>
              <Grid item container alignItems="stretch" spacing={1}>
                {/* title starts */}
                <Grid item xs={12}>
                  <Typography className={classes.title}>{content.general.title}</Typography>
                </Grid>
                {/* title ends */}

                {/* description starts */}
                <Grid item xs={12} md={width}>
                  <Description className={classes.markdown_text} source={content.general.text} />
                </Grid>
                {/* description ends */}

                {/* picture starts */}
                {content.general.picture && (
                  <Grid item ms={12} md={4} style={{ textAlign: "center" }}>
                    <Picture src={content.general.picture} />
                  </Grid>
                )}
                {/* picture ends */}

                {/* code example starts */}
                {content.general.code_example && (
                  <Grid item xs={12}>
                    <ExampleCode className={classes.markdown_text} source={content.general.code_example} />
                  </Grid>
                )}
                {/* code example ends */}

              </Grid>
            </Paper>

            {"video" in content && (
              <>
                <Grid item xs={12}>
                  <Typography className={classes.subtitle}>Videos</Typography>
                </Grid>

                {/* videos starts */}
                <Grid item container spacing={2}>
                  {content.video.slice(0, 2).map((video) => {
                    return (
                      <Grid item xs={12} sm={12} md={6}>
                        <Card className={classes.paper} variant={"outlined"}>
                          {renderVideo(video)}
                        </Card>
                      </Grid>
                    );
                  })}
                  {/* videos ends */}

                  {/* video collapse starts */}
                  {content.video.length > 2 && (
                    <Grid item xs={12}>
                      <Collapse in={showMoreVideos}>
                        <Grid container spacing={2}>
                          {content.video.slice(2).map((video) => {
                            return (
                              <Grid item xs={12} sm={12} md={6}>
                                <Paper square={true}>{renderVideo(video)}</Paper>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </Collapse>
                    </Grid>
                  )}
                  {/* video collapse ends */}
                </Grid>
              </>
            )}

            {/* expand-collapse button of videos */}
            {"video" in content && content.video.length > 2 && (
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <ExpandCollapseButton expand={!showMoreVideos} onClick={() => setShowMoreVideos((old) => !old)} />
              </Grid>
            )}

            {/* getting started section starts */}
            {content.tutorials['getting_started'].length > 0 && (
              <Grid item xs={12}>
                <Section
                  type="tutorials"
                  content={content.tutorials['getting_started']}
                  title="Getting Started"
                />
              </Grid>
            )}
            {/* getting started section ends */}

            {/* advanced section starts */}
            {content.tutorials['advanced'].length > 0 && (
              <Grid item xs={12}>
                <Section
                  type="tutorials"
                  content={content.tutorials['advanced']}
                  title="Advanced"
                />
              </Grid>
            )}
            {/* advanced section ends */}

            {/* links section starts */}
            {content.links.length > 0 && (
              <Grid item xs={12}>
                <Section type="links" content={content.links} title="Links" />
              </Grid>
            )}
            {/* links section ends */}

            {/* publications section starts */}
            {content.papers.length > 0 && (
              <Grid item xs={12}>
                <Section type="publications" content={content.papers} title="Publications" />
              </Grid>
            )}
            {/* publication section ends */}
          </Grid>
        </main>
      </DefaultLayout>
    </body>
  );
}
