import React from "react";
import { Typography, Grid, Button } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import baseStyle from "../../css/const";
import tools from "../../json/tools.json";
import InfoPaper from "../../components/cards/info-card";
import Paper from "@material-ui/core/Paper";
import DefaultLayout from "../../components/layouts/default-layout";
import useDocTitle from "../../hooks/use-doc-title";
import useTracker from "../../hooks/use-tracker";
import robots from "../../json/robots.json"
import team from "../../json/team.json";
import TeamTable from "../../components/tables/team-table";
import RobotsTable from "../../components/robots/robots-table";
import {Table, TableHead, TableRow, TableCell} from "@material-ui/core";
import Link from "@material-ui/core/Link";

export default function Dataset() {
  // set document title
  const [title, setTitle] = useDocTitle("Datasets");

  // setup tracker after setting the document title
  useTracker();

  const classes = baseStyle();

  return (
    <DefaultLayout>
      <div className="container">

        <div className="row">
          <div className="col">
            <h1 className="display-5">Datasets</h1>
            <p>Here we list all datasets associated with the CoBRA benchmarking suite:</p>
          </div>
        </div>

        <Table size={"small"}>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography className="h6">Name</Typography>
            </TableCell>
            <TableCell>
              <Typography className="h6">Description</Typography>
            </TableCell>
            <TableCell>
              <Typography className="h6">Link</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
          <TableRow>
            <TableCell>
              <Typography>CMM</Typography>
            </TableCell>
            <TableCell>
              <Typography>CMM one-liner</Typography>
            </TableCell>
            <TableCell>
              <Typography><Link href="/tools/cmm">Details</Link></Typography>
            </TableCell>
          </TableRow>
        </Table>
      </div>
    </DefaultLayout>


);
}
